import React, { useState, useEffect } from 'react';

// vendor
import { FiArrowLeft, FiArrowRight, FiCrop, FiImage, FiInfo, FiX } from 'react-icons/fi';
import { BiEdit, BiGridAlt, BiRedo, BiSlideshow, BiUndo } from 'react-icons/bi';
import { HiCheckCircle } from 'react-icons/hi';
import { Link, navigate } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import anime from 'animejs';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue, atomFamily, useSetRecoilState, } from 'recoil';
import { motion, AnimatePresence } from 'framer-motion';
import { RecoilUndoRoot, useUndo, useRedo, useBatching } from 'recoil-undo';
import { IoIosCloseCircle, IoIosCheckmarkCircle } from "react-icons/io";
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import uploadcare from 'uploadcare-widget';
import _ from 'lodash';

// core
import { constructUploadcareUrl } from "@mattevans-dev/editsquare.core/utils/mockup-utils";
import ElementsConfiguration from '@mattevans-dev/editsquare.core/utils/elementsConfiguration';
import { getFabricInstance, seekFabricInstance } from '@mattevans-dev/editsquare.core/services/fabricService';

// components
import { projectConfigurationAtom, selectedCanvasElementSelector, selectedCanvasElementUIDAtom, selectedSceneElementsSelector, } from '../utils/atomStore';
import { mockupsLibrary } from "../library/mockupsLibrary.mjs";
import Viewer from '../components/recoil/viewer';
import TimelineRailMockup from '../components/recoil/timelineRailMockup';
import { draggedElementArgumentsSelector } from '../utils/atomStore';
import { curLabel, isBrowser } from "../utils/utils";
import RightRail from '../components/recoil/rightRail';
import Modal from '../components/modal';
import MockupHeader from '../components/mockup/header';
import MockupContainer from '../components/mockup/mockupContainer';
import MockupTemplates from '../components/mockup/mockupTemplates';
import MockupUploads from '../components/mockup/mockupUploads';
import ExportButton from '../components/exportButton';

const myTimeline = isBrowser ? anime.timeline({
    autoplay: false,
    loop: false,
    duration: 1000,
}) : null;

const Mockup = () => {
    const [projectConfiguration, setProjectConfiguration] = useRecoilState(
        projectConfigurationAtom
    );
    const canvasElements = useRecoilValue(selectedSceneElementsSelector);
    const setCanvasElements = useSetRecoilState(selectedCanvasElementSelector);
    const draggedElementArguments = useRecoilValue(
        draggedElementArgumentsSelector
    );
    const [
        selectedCanvasElementUID,
        setSelectedCanvasElementUID,
    ] = useRecoilState(selectedCanvasElementUIDAtom);
    const [resolution, setResolution] = useState('4k');
    const [activeProductId, setActiveProductId] = useState('');
    const [products, setProducts] = useState([]);
    const [changeTemplateActive, setChangeTemplateActive] = useState(false);
    const [licenseModal, setLicenseModal] = useState(false);
    const [mediaModal, setMediaModal] = useState(false);
    const [cropModal, setCropModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState({ url: null, uuid: null });
    const [loading, setLoading] = useState(true);

    const onResolutionChange = (resolution) => {
        setResolution(resolution);
        if(resolution === '4k') {
            setActiveProductId(selectedTemplate.uhdProductId ?? process.env.GATSBY_PRODUCT_4K_ID) // change this to 4k when added on paddle
        }
        else {
            setActiveProductId(selectedTemplate.hdProductId ?? process.env.GATSBY_PRODUCT_HD_ID)
        }
    }

    const getActiveProductPrice = (activeProductId) => {
        if(activeProductId && !_.isEmpty(products)) {
            const activeProduct = products.filter((product) => ( product.product_id == activeProductId ));
            const currency = curLabel(activeProduct[0].currency);
            const priceFormatted = currency + activeProduct[0].price.gross;
            return priceFormatted;
        }
        return '...';
    }

    const techSpecInfo =
        resolution === '4k' ? (
            <p className="text-gray-700">
                <strong>Resolution: </strong> 3840 x 2160
                <br />
                <strong>Frame Rate: </strong> {canvasElements ? canvasElements[0]?.frameRate : 0}fps
                <br />
                <strong>Codec: </strong> ProRes HQ &amp; H264
                <br />
                <strong>File: </strong> mov &amp; mp4
            </p>
        ) : (
            <p className="text-gray-700">
                <strong>Resolution: </strong> 1920 x 1080
                <br />
                <strong>Frame Rate: </strong> {canvasElements ? canvasElements[0]?.frameRate : 0}fps
                <br />
                <strong>Codec: </strong> ProRes HQ &amp; H264
                <br />
                <strong>File: </strong> mov &amp; mp4
            </p>
        );

    // setup Paddle
    useEffect(() => {
        // set default state if template already selected in canvaselements
        if(Array.isArray(canvasElements) && canvasElements.length == 1) {
            const uid = canvasElements[0].templateId;
            const template = _.find(mockupsLibrary, {uid: uid});
            setLoading(true);
            setSelectedTemplate(template);
            setSelectedCategory(template.category);
            // get pricing information
            const url = process.env.GATSBY_MOCKUP_API + '/pricing/';
            const pricingArgs = [process.env.GATSBY_PRODUCT_HD_ID, process.env.GATSBY_PRODUCT_4K_ID];
            if(template && template.hdProductId) { pricingArgs.push(template.hdProductId); }
            if(template && template.uhdProductId) { pricingArgs.push(template.uhdProductId); }
            console.log(">>>", template);
            console.log(">>>", pricingArgs);
            const params = { product_ids: pricingArgs.join(",")};
            axios(url, { params })
            .then((res) => {
                setProducts(res.data.response.products);
                setActiveProductId(process.env.GATSBY_PRODUCT_4K_ID);
            });
        }
    }, []);

    useEffect(() => {
        let count = 0;
        if(document.getElementById('viewer')) {
            const fbcCanvas = getFabricInstance();
            function waitForElementsToReady() {
                console.log("waiting for elements to load");
                const allReady = fbcCanvas
                    .getObjects()
                    .map((o) => (o.ready ? o.ready : false));
                if (allReady.length == 2 && _.every(_.slice(allReady, 1))) {
                    console.log("DONE", allReady);
                    setLoading(false);
                } else {
                    count = count + 1;
                    if (count < 100) {
                        setTimeout(waitForElementsToReady, 1000);
                    }
            }
            }
            setTimeout(waitForElementsToReady, 1000);
        }
    }, [selectedTemplate])

    const onPurchaseClick = () => {
     setLicenseModal('true');
  };

    const templateSubmit = (selectedTemplateVideo) => {
        setLoading(true);
        projectConfiguration.handleReset(); // reset timeline to 0
        setSelectedTemplate(selectedTemplateVideo);
        // get existing config
        let placeholderArgs = {};
        if(canvasElements.length == 1) {
            const mockupElem = canvasElements[0];
            placeholderArgs = mockupElem?.placeholder ?? {};
        }
        // retrieve element config
        const elementConfig = _.find(ElementsConfiguration, { uid: selectedTemplateVideo.elementType });
        // merge inline config, element config and library config
        let newUid = uuidv4();
        let placeholder = _.merge(
            {},
            placeholderArgs,
            elementConfig.defaultArgs?.placeholder,
            selectedTemplateVideo.defaultArgs?.placeholder,
            // carry over previous args
            {
                uuid: projectConfiguration.scenes[0].elements[0].placeholder.uuid,
                url: projectConfiguration.scenes[0].elements[0].placeholder.url,
                image: projectConfiguration.scenes[0].elements[0].placeholder.image
            }
        );
        let args = _.merge(
            { },
            elementConfig.defaultArgs || {},
            selectedTemplateVideo.defaultArgs || {},
            { 
                elementType: selectedTemplateVideo.elementType,
                left: 0,
                top: 0,
                uid: newUid,
                templateId: selectedTemplateVideo.uid,
                placeholder: placeholder
            }
        );
        // replace canvas elements with new args
        // and update project duration
        const projectConfig = _.merge(
            {}, 
            projectConfiguration,
            { 
                duration: args.duration,
                selectedTemplateId: selectedTemplateVideo.id,
                scenes: [
                    {
                        name: 'Mockup',
                        elements: [ args ]
                    }
                ]
            }
        );
        const canvas = getFabricInstance();
        canvas.set({"duration": projectConfig.duration});
        setProjectConfiguration(projectConfig);
    }

    const freePurchase = () => {
        window.Paddle.Environment.set(process.env.GATSBY_PRODUCT_ENV);
        var renderId = null;
        console.log("set-up paddle", projectConfiguration.selectedTemplateId);
        window.Paddle.Setup({
            vendor: parseInt(process.env.GATSBY_VENDOR_ID),
            // https://developer.paddle.com/reference/paddle-js/checkout-events
            eventCallback: data => {
                // create pending order when user completes the first modal screen
                // User has proceeded past the email checkout step
                if (data.event === 'Checkout.Login') {
                    axios({
                        method: 'post',
                        url: process.env.GATSBY_MOCKUP_API + '/renders',
                        data: {
                            checkoutId: data.eventData.checkout.id,
                            templateId: projectConfiguration.selectedTemplateId, // todo - this and below needs changing
                            videoData: projectConfiguration,
                        },
                    })
                        .then(res => {
                            renderId = res.data.publishRender.id;
                        })
                        .catch(err => {
                            console.log(err);
                            alert(
                                'There was an issue. Please refresh and try again'
                            );
                        });
                }
                // onSuccess
                else if (data.event === 'Checkout.Complete' && isBrowser) {
                    window.location.href =
                        window.location.origin +
                        `/order-success/${renderId}`; //redirect to success page
                }
            },
        });
        // open Paddle checkout
        window.Paddle.Checkout.open({
            product: process.env.GATSBY_PRODUCT_LQ_ID,
            allowQuantity: false,
        });
    }

    // Launch Paddle Checkout on click
    const purchaseModal = () => {
        window.Paddle.Environment.set(process.env.GATSBY_PRODUCT_ENV);
        var renderId = null;
        console.log("set-up paddle", projectConfiguration.selectedTemplateId);
        window.Paddle.Setup({
            vendor: parseInt(process.env.GATSBY_VENDOR_ID),
            // https://developer.paddle.com/reference/paddle-js/checkout-events
            eventCallback: data => {
                // create pending order when user completes the first modal screen
                // User has proceeded past the email checkout step
                if (data.event === 'Checkout.Login') {
                    axios({
                        method: 'post',
                        url: process.env.GATSBY_MOCKUP_API + '/renders',
                        data: {
                            checkoutId: data.eventData.checkout.id,
                            templateId: projectConfiguration.selectedTemplateId, // todo - this and below needs changing
                            videoData: projectConfiguration,
                        },
                    })
                        .then(res => {
                            renderId = res.data.publishRender.id;
                        })
                        .catch(err => {
                            console.log(err);
                            alert(
                                'There was an issue. Please refresh and try again'
                            );
                        });
                }
                // onSuccess
                else if (data.event === 'Checkout.Complete' && isBrowser) {
                    window.location.href =
                        window.location.origin +
                        `/order-success/${renderId}`; //redirect to success page
                }
            },
        });
        // open Paddle checkout
        window.Paddle.Checkout.open({
            product: activeProductId,
            allowQuantity: false,
        });
    };

    // replace placeholder and reload
    const mediaSubmit = (media) => {
        // force download of image before replacing it (otherwise this can cause race conditions)
        fetch(media.url).then(res => {
            _replaceElement({
                placeholder: {
                    originalUrl: media.originalUrl,
                    cdnUrl: media.cdnUrl, 
                    url: media.url,
                    uuid: media.uuid,
                },
            });
        })
    };

    // update configuration with new UID to force new component creation
    // this is because images are loaded on initialisation
    const _replaceElement = newConfig => {
        let writableProjectConfiguration = _.cloneDeep(projectConfiguration);
        let newUid = uuidv4();
        writableProjectConfiguration.scenes[0].elements[0] = _.merge(
            {},
            writableProjectConfiguration.scenes[0].elements[0],
            { uid: newUid },
            newConfig
        );
        setProjectConfiguration(writableProjectConfiguration);
    };

    // const replaceMedia = () => {
    //     const dialog = uploadcare.openDialog(null, 'file', {
    //         multiple: false,
    //         inputAcceptTypes: 'image/*',
    //         crop: '9:16',
    //         tabs: 'file',
    //     });
    //     dialog.done(result => {
    //         result.promise().done(info => {
    //             const url = constructUploadcareUrl(info.cdnUrl); 
    //             _replaceElement({
    //                 placeholder: {
    //                     url: url,
    //                     uuid: info.uuid,
    //                 },
    //             });
    //         });
    //     });
    // };

    const cropMedia = () => {
        const template = canvasElements[0];
        const file = uploadcare.fileFrom(
            'uploaded',
            template?.placeholder?.url
        );
        const dialog = uploadcare.openDialog(file, 'preview', {
            multiple: false,
            inputAcceptTypes: 'image/*',
            crop: selectedTemplate?.aspectRatio ? selectedTemplate.aspectRatio : '9:16',
            tabs: '',
            validators: null,
        });
        dialog.done(result => {
            result.promise().done(info => {
                const url = constructUploadcareUrl(info.cdnUrl); 
                _replaceElement({
                    placeholder: {
                        url: url,
                        uuid: info.uuid,
                    },
                });
            });
        });
    };

    //https://ucarecdn.com/0963d2b9-92a9-405e-8c5b-8b42e5861202/-/preview/1162x693/-/setfill/ffffff/-/format/jpeg/-/progressive/yes/
    // Validation before render.
    let canvasElement = null;
    if (Array.isArray(canvasElements) && canvasElements.length == 1) {
        canvasElement = canvasElements[0];
    }
    // verify template has already been selected
    const template = _.find(mockupsLibrary, {
        uid: canvasElement?.templateId,
    });
    if (template == null) {
        isBrowser && navigate('/template');
        return(null);
    }
    // verify media has already been selected
    if(!canvasElement?.placeholder?.uuid) {
        isBrowser && navigate('/choose-media');
        return(null);
    }
    // select mockup element
    setSelectedCanvasElementUID(canvasElement.uid);

    // set upload care args for modals
    let initialUploadArgs = null;
    if (canvasElement?.placeholder?.uuid && canvasElement?.placeholder?.url) {
        initialUploadArgs = { url: canvasElement?.placeholder?.url, uuid: canvasElement?.placeholder?.uuid };
    }

    return (
        
        <>
            <MockupContainer>
                <MockupHeader />
                    {/* Media Modal */}
                    <Modal size="lg" isOpen={mediaModal} handleClose={() => setMediaModal(false)}>
                        <h1 className="font-bold mb-3">Replace your media</h1>
                        <hr/>
                        <MockupUploads 
                            aspectRatio={selectedTemplate?.aspectRatio ? selectedTemplate.aspectRatio : '9:16'} 
                            // initialUpload={initialUploadArgs}
                            onSelect={(fileInfo) => {
                                // use cdnUrl, and add the /-/preview if it doesn't exist
                                let baseUrl = fileInfo.cdnUrl;
                                if(!baseUrl.endsWith("-/preview/")) {
                                    baseUrl += "-/preview/";
                                }
                                const media = { url: baseUrl + '2048x2048/-/resize/2048x2048/', cdnUrl: baseUrl, uuid: fileInfo.uuid, originalUrl: fileInfo.originalUrl };
                                setSelectedMedia(media);
                            }}
                            onDeselect={() => {
                                setSelectedMedia(null); 
                            }}
                            onDone={(fileInfo) => {
                                // use cdnUrl, and add the /-/preview if it doesn't exist
                                let baseUrl = fileInfo.cdnUrl;
                                if(!baseUrl.endsWith("-/preview/")) {
                                    baseUrl += "-/preview/";
                                }
                                const media = { url: baseUrl + '2048x2048/-/resize/2048x2048/', cdnUrl: baseUrl, uuid: fileInfo.uuid, originalUrl: fileInfo.originalUrl };
                                mediaSubmit(media);
                                setMediaModal(false);
                            }}
                            tabs={'file url'}
                        />
                    </Modal>
                    {/* Crop Modal */}
                    <Modal  size="lg" isOpen={cropModal} handleClose={() => setCropModal(false)}>
                        <h1 className="font-bold mb-3">Crop your media</h1>
                        <hr/>
                        <div className="uploadcare-cropmodal">
                            <MockupUploads 
                                aspectRatio={selectedTemplate?.aspectRatio ? selectedTemplate.aspectRatio : '9:16'} 
                                initialUpload={initialUploadArgs}
                                onSelect={(fileInfo) => {
                                    const media = { url: fileInfo.cdnUrl, uuid: fileInfo.uuid };
                                    setSelectedMedia(media);
                                }}
                                onDeselect={() => {
                                    setCropModal(false);
                                }}
                                onDone={(fileInfo) => {
                                    // use cdnUrl, and add the /-/preview if it doesn't exist
                                    let baseUrl = fileInfo.cdnUrl;
                                    if(!baseUrl.endsWith("-/preview/")) {
                                        baseUrl += "-/preview/";
                                    }
                                    const media = { url: baseUrl + '2048x2048/-/resize/2048x2048/', cdnUrl: baseUrl, uuid: fileInfo.uuid, originalUrl: fileInfo.originalUrl };
                                    mediaSubmit(media);
                                    setCropModal(false);
                                }}
                                tabs={''}
                            />
                        </div>
                    </Modal>
                    {/* Licenseing Modal */ }
                    <Modal size="lg" isOpen={licenseModal} handleClose={() => setLicenseModal(false)}>
                      <h1 className="font-bold mb-0.5">Licensing Info</h1>
                      <div className="mb-4">
                        <p className="mb-2">Before you checkout, we just want to run through the legal stuff...</p>
                        <p className="mb-6">By purchasing a video, we will grant you a royalty-free, perpetual, irrevocable, non-exclusive, non-transferable license to use the video generated by our platform in a single project.</p>
                        <div className="flex mb-5">
                            <div className="w-full md:w-1/2 pr-3 border-r">
                                <h2 className="font-bold mb-3 border-b pb-2">Do</h2>
                                <div className="flex flex-col space-y-2 text-sm">
                                <div className="flex items-start">
                                      <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5"/></div>
                                      <div>Use in a commercial or personal project.</div>
                                </div>
                                <div className="flex items-start">
                                      <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5"/></div>
                                      <div>Use in a free or paid advertisement. E.g on your website or on social media etc.</div>
                                </div>
                                <div className="flex items-start">
                                      <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5"/></div>
                                      <div>Use multiple times in a single project. E.g if you're creating cutdown videos of a full-length promo.</div>
                                </div>
                                <div className="flex items-start">
                                      <div><IoIosCheckmarkCircle className="inline-flex w-6 h-full text-green-500 mr-1.5"/></div>
                                      <div>No attribution or credit is required.</div>
                                </div>
                                </div>
                            </div>
      
                            <div className="w-full md:w-1/2 pl-3">
                                <h2 className="font-bold mb-3 border-b pb-2">Don't</h2>
                                <div className="flex flex-col space-y-2 text-sm">
                                  <div className="flex items-start">
                                      <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5"/></div>
                                      <div>Use in a TV broadcast, movie theater or VOD project. <a className="text-indigo-600" href="mailto:hello@editsquare.com">Contact us</a> if this is required.</div>
                                  </div>
                                  <div className="flex items-start">
                                      <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5"/></div>
                                      <div>Use harmful, pornographic or offensive material in your video.</div>
                                  </div>
                                  <div className="flex items-start">
                                      <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5"/></div>
                                      <div>Use the video as a basis for merchandising, e.g printing on t-shirts.</div>
                                  </div>
                                  <div className="flex items-start">
                                      <div><IoIosCloseCircle className="inline-flex w-6 h-full text-red-500 mr-1.5"/></div>
                                      <div>Redistribute or sell the video. E.g on other stock platforms.</div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <p className="mb-4"><a className="text-indigo-600" href="#" target="_blank">Click here</a> to read our full license agreement and terms.</p>
      
                        <hr className="mb-4" />
                        <p className="text-gray-700 font-semibold">Please click 'Accept' below to agree to our terms and conditions and continue to payment..</p>
                      </div>
      
                      <div className="flex justify-end">
                          <div className="flex">
                              <button
                                  onClick={() => {
                                    setLicenseModal(false);
                                    purchaseModal();
                                  }}
                                  className="ml-2 flex items-center justify-center cursor-pointer text-center border border-green-500 rounded text-sm py-2 px-4 bg-gradient-to-b from-green-500 to-green-600 text-white hover:from-green-500 hover:to-green-500"
                              >
                                  Accept &amp; Checkout
                              </button>
                          </div>
                      </div>
                  </Modal>

                <div className="flex-1 relative pb-6 overflow-hidden">

                  {/* Change Template Drawer */}
                  <AnimatePresence initial={false}>
                  {changeTemplateActive && (
                      <>
                      <motion.div
                      key="changeTemplateDrawer"
                      className="absolute h-full top-0 right-0 bg-gray-200 w-5/6 md:w-88 px-4 py-5 shadow-lg z-50"
                      initial={{ x: '100%' }}
                      animate={{ x: 0, transition: { type: 'tween', ease: 'easeOut', duration: 0.35, delay: 0.25 } }}
                      exit={{ x: '100%', transition: { type: 'tween', ease: 'easeOut', duration: 0.35 } }}
                      >
                          <div className="flex flex-wrap justify-between items-center">
                            <h3 className="font-semibold mb-0.5">Change Template</h3>
                            <FiX onClick={() => setChangeTemplateActive(false)} className="cursor-pointer text-xl text-gray-500" />
                          </div>
                          <div className="templateGridSidebar overflow-y-auto">
                            <MockupTemplates 
                                wrapperClassName="w-full my-3"
                                take={6}
                                // category={selectedCategory} 
                                aspectRatio={selectedTemplate?.aspectRatio}
                                selected={selectedTemplate?.uid} 
                                onClick={(video) => { templateSubmit(video); }}
                                onKeyPressEnter={(video) => { templateSubmit(video); setChangeTemplateActive(false); }}
                            />
                            <hr className="my-4 border-gray-400" />
                            <Link to="/template" className="inline-flex items-center cursor-pointer bg-white shadow-sm hover:border-gray-400 border text-indigo-600 transition duration-150 leading-none rounded py-3 px-4"><FiArrowLeft className="mr-1" />View more templates</Link>
                          </div>
                      </motion.div>

                      <motion.div
                          key="changeTemplateOverlay"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0, transition: { delay: 0.25 } }}
                          onClick={() => setChangeTemplateActive(false)}
                          className="absolute h-full w-full bg-gray-900 bg-opacity-25 z-40"
                          ></motion.div>
                      </>
                  )}
                  </AnimatePresence>

                  {/* Mockup Editor */}
                    <div className="pt-8">

                    <div className="flex flex-wrap items-center max-w-screen-2xl mx-auto px-10 mb-2.5">
                        <div className="w-full md:flex-1">
                            <h2 className="text-2xl font-bold font-dm mb-2">Edit your video mockup</h2>
                        </div>
                        <div className="w-full md:w-96">
                            <div className="flex justify-end pl-10">
                                {/* <UndoButtons /> */}
                                <div>
                                    <div onClick={() => setChangeTemplateActive(true)} className="flex items-center cursor-pointer bg-white shadow-sm hover:border-gray-400 border font-medium text-indigo-600 transition duration-150 text-sm leading-none rounded py-2 px-3">
                                        <BiEdit className="text-indigo-600 text-lg mr-1.5" />{' '}
                                        Change Template
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto max-w-screen-2xl px-10">
                        <div className="flex flex-wrap -mx-5">
                            <div className="w-full lg:flex-1 overflow-hidden px-5">
                                {/** Viewer */}
                                <div className="relative mb-4 lg:mb-0">
                                    <Viewer
                                        timeline={myTimeline}
                                        draggedElementArguments={
                                            draggedElementArguments
                                        }
                                        width={1920/3}
                                        height={1080/3}
                                    />

                                    <div className="mockupTimeline rounded z-10 relative top-0 w-full" style={{paddingBottom: '56.25%'}}>
                                        <div className="absolute bottom-0 w-full p-4 playerGradient">
                                                <TimelineRailMockup
                                                    timeline={myTimeline}
                                                    framerate={canvasElements[0].frameRate}
                                                />
                                        </div>
                                        {loading && <div className="absolute w-full h-full bg-black opacity-50">
                                            <div className="flex justify-center items-center h-full">
                                                <svg className="animate-spin h-16 w-16 md:h-18 md:w-18 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                                </svg>
                                            </div>
                                        </div>}
                                    </div>
                                </div>

                                {/** Playback */}
                                <div className="hidden alert mb-3">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            {/* Heroicon name: information-circle */}
                                            <svg
                                                className="h-5 w-5 text-gray-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        <div className="ml-1 flex-1 md:flex md:justify-between">
                                            <p className="text-sm text-gray-600">
                                                Add notice here, if playback is
                                                choppy not to worry...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-96 px-5">
                                <div className="flex flex-col h-full">
                                    <div className="flex-1 bg-white rounded shadow pt-4 overflow-y-auto mb-4">
                                        <div className="px-4 mb-5">
                                            <div className="flex mb-3 -mx-1.5">
                                                <div className="w-1/2 px-1.5">
                                                    <img
                                                        src={ canvasElements[0]?.placeholder?.cdnUrl + '150x150/' }
                                                        className="rounded object-contain h-17 w-full bg-gray-200"
                                                    />
                                                </div>
                                                <div className="w-1/2 px-1.5 text-sm">
                                                    <div
                                                        onClick={() =>
                                                            setMediaModal(true)
                                                            // replaceMedia()
                                                        }
                                                        className="flex items-center cursor-pointer text-red-500 text-center mb-1"
                                                    >
                                                        <FiImage className="mr-1.5" />
                                                        Replace Image
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            setCropModal(true)
                                                            // cropMedia()
                                                        }
                                                        className="flex items-center cursor-pointer text-indigo-600 text-center mb-1"
                                                    >
                                                        <FiCrop className="mr-1.5" />
                                                        Crop Image
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <RightRail />
                                    </div>

                                    <div className="bg-white shadow py-3 px-4 rounded">
                                        <div className="flex justify-between items-center mb-1.5">
                                            <div className="text-xs uppercase font-bold text-gray-600 tracking-tight">
                                                Purchase Options
                                            </div>
                                            <div className="flex items-center text-xs text-gray-500 tracking-tight">
                                                <Tooltip
                                                    position="top"
                                                    trigger="click"
                                                    html={
                                                        <div className="text-sm">
                                                            {techSpecInfo}
                                                        </div>
                                                    }
                                                    className="items-center cursor-pointer"
                                                    arrow={true}
                                                    theme="light"
                                                    duration={200}
                                                    style={{ display: 'flex' }}
                                                >
                                                    <FiInfo className="mr-0.5" />
                                                    Tech Specs
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex bg-gray-200 rounded mb-2">
                                            <div className="flex-1 pr-0.5">
                                                <div
                                                    className={`m-1.5 mr-0 p-2 rounded text-center transition duration-150 ${
                                                        resolution === '4k'
                                                            ? 'shadow text-indigo-600 bg-white'
                                                            : 'cursor-pointer hover:bg-white hover:bg-opacity-50'
                                                    } `}
                                                    onClick={() =>
                                                        onResolutionChange('4k')
                                                    }
                                                >
                                                    4K
                                                </div>
                                            </div>
                                            <div className="flex-1 pl-0.5">
                                                <div
                                                    className={`m-1.5 ml-0 p-2 rounded text-center transition duration-150 ${
                                                        resolution === 'hd'
                                                            ? 'shadow text-indigo-600 bg-white'
                                                            : 'cursor-pointer hover:bg-white hover:bg-opacity-50'
                                                    } `}
                                                    onClick={() =>
                                                        onResolutionChange('hd')
                                                    }
                                                >
                                                    HD
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-t mb-2.5 pt-2.5">
                                            <div className="flex items-center text-gray-700 text-xs font-medium mb-0.5">Incl.&nbsp;<a className="link" href="https://mockupclips.com/license" target="_blank"> Commercial License</a> <HiCheckCircle className="text-green-500 ml-1"/></div>
                                            <div class="text-xs leading-tight text-gray-600 mb-1">By clicking Purchase Video, you accept the <a className="link" href="https://editsquare.com/terms" target="_blank">terms of use</a> and <a className="link" href="https://editsquare.com/privacy-policy" target="_blank">privacy policy</a></div>
                                        </div>
                                        <button
                                            onClick={() => purchaseModal()}
                                            className="flex w-full items-center justify-center cursor-pointer text-center border border-transparent rounded h-11 px-4 bg-indigo-600 hover:bg-indigo-700 transition duration-200 text-white font-semibold mb-1"
                                        >
                                            <span className="font-normal text-white text-opacity-75 mr-1.5">
                                                {getActiveProductPrice(activeProductId)}
                                            </span>
                                            Purchase Video
                                        </button>
                                        <div className="flex items-center text-xs leading-tight text-gray-600">Or&nbsp;<a className="link" href="#" onClick={() => {freePurchase();}}> Render Free Preview</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className="bg-white shadow-top w-full">
                <div className="flex items-center justify-end text-sm py-3 max-w-screen-2xl mx-auto px-10">
                    <button
                        onClick={() => {
                            navigate('/choose-media');
                        }}
                        className="py-3 px-4 transition duration-150 hover:bg-opacity-75 font-medium rounded text-gray-600 bg-gray-200"
                    >
                        <span className="opacity-75 font-bold mr-1">Back:</span>Choose Media
                    </button>
                    {/* <button
                        onClick={() => onPurchaseClick()}
                        className="flex items-center justify-center cursor-pointer text-center border border-transparent rounded h-11 px-4 bg-green-500 hover:bg-green-600 transition duration-200 text-white font-semibold"
                    >
                        <span className="font-normal text-white text-opacity-75 mr-1.5">
                            {getActiveProductPrice(activeProductId)}
                        </span>
                        Purchase Video
                    </button> */}

                    {/* <button onClick={() => { var win = window.open(); win.document.write('<iframe src="data:text/html, <html contenteditable>' + encodeURIComponent(JSON.stringify(projectConfiguration)) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'); }}>Project Config</button> */}
                    {/* <DebugCanvasButton /> */}
                </div>
                </div>
            </MockupContainer>
        </>
    );
};

// const UndoButtons = () => {
//     const undo = useUndo()
//     const redo = useRedo()
//     return (
//       <div className="flex">
//         <button onClick={undo} className="cursor-pointer flex items-center bg-white shadow-sm hover:border-gray-400 border text-indigo-600 transition duration-150 text-xs rounded py-1.5 px-2 mr-2">
//             <BiUndo className="text-indigo-600 text-lg mr-1" />
//             Undo
//         </button>
//         <button onClick={redo} className="cursor-pointer flex items-center bg-white shadow-sm hover:border-gray-400 border text-indigo-600 transition duration-150 text-xs rounded py-1.5 px-2 mr-2">
//             <BiRedo className="text-indigo-600 text-lg mr-1" />
//             Redo
//         </button>
//       </div>
//     )
//   }

const MockupWrap = () => {
    return (
        <RecoilUndoRoot>
            <Mockup />
        </RecoilUndoRoot>
    );
};

export default MockupWrap;
