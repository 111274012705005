import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronUp } from "./icons";
import { AiOutlineFontColors, AiOutlineFullscreen } from "react-icons/ai";
import { MdRoundedCorner, MdBlurLinear } from "react-icons/md";
import { BiRectangle, BiBorderOuter, BiSliderAlt, BiMove, BiColorFill, BiDroplet, BiStopwatch} from "react-icons/bi";
import { BsTextareaT} from "react-icons/bs";
import { CgFilters } from "react-icons/cg";

function Accordion({ name, icon, expanded, setExpanded, children }) {
    const isOpen = (name === expanded);
    const classes = 'h-full w-4 mr-2 opacity-75';
    var icon;
    if(icon === "font") { icon = <AiOutlineFontColors className={classes} />}
    else if(icon === "textbox") { icon = <div className="mr-1.5"><div className={`mx-0.5 w-3 rounded-sm bg-gray-800 h-3 text-white text-center`} style={{fontSize: '0.5rem'}}>T</div></div>}
    else if(icon === "stroke") { icon = <div className="mr-1.5"><div className={`mx-0.5 w-3 rounded-sm border border-gray-800 h-3`}></div></div>}
    else if(icon === "shadow") { icon = <div className="mr-1.5"><div className={`mx-0.5 shadow rounded-sm border border-transparent w-3 bg-white h-3`} style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'}}></div></div>}
    else if(icon === "transform") { icon = <BiMove className={classes} />}
    else if(icon === "filters") { icon = <CgFilters className={classes} />}
    else if(icon === "sizing") { icon = <AiOutlineFullscreen className={classes} />}
    else if(icon === "roundness") { icon = <MdRoundedCorner className={classes} />}
    else if(icon === "blur") { icon = <BiDroplet className={classes} />}
    else if(icon === "color") { icon = <BiColorFill className={classes} />}
    else if(icon === "hsl") { icon = <BiSliderAlt className={classes} />}
    else if(icon === "timing") { icon = <BiStopwatch className={classes} />}
    else if(icon === "reflect") { icon = <MdBlurLinear className={classes} />}

    return (
        <>
            <div className="cursor-pointer flex justify-between items-center border-t px-5 py-2 bg-gray-100 transition transition-duration-75 hover:bg-gray-200" onClick={() => setExpanded(isOpen ? false : name)}>
                <div className="flex items-center capitalize text-sm font-bold">{icon}{name}</div>
                <div>
                    <motion.div
                        className="ml-1"
                        animate={isOpen ? "open" : "collapsed"}
                        variants={{
                            open: { rotate: 0, originX: '50%', originY: '50%' },
                            collapsed: { rotate: 180 }
                        }}
                    >
                        <ChevronUp className="h-4" />
                        </motion.div>
                </div>
            </div>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <div className="overflow-hidden">
                        <motion.div
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                                open: { opacity: 1, height: "auto", overflow: "hidden", y: 0 },
                                collapsed: { opacity: 0, height: 0, overflow: "hidden", y: -75 }
                            }}
                            transition={{ duration: 0.35, ease: [0.04, 0.62, 0.23, 0.98] }}
                        >
                            <div className="px-5 py-4 mb-1">
                                <div className="flex flex-wrap -mx-2">
                                {children}
                                </div>
                            </div>
                        </motion.div>
                    </div>
                )};
        </AnimatePresence>
        </>
    )
}
export default Accordion;