import React from 'react';
import Select from 'react-select';
import { get, set } from "lodash";
import { selectStyle } from '../../utils/styles';
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function BtnGroup({ label, options, defaultValue, className, size, target, targetProperty }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);

    const onClickHandler = (e) => {
        const updatedCanvasElement = { uid: target };
        if (Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
        } else {
            set(updatedCanvasElement, targetProperty, e);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    return (
        <div className={`${className ? className : ''}`}>
            <label className={`block leading-4 font-medium text-gray-700 mb-1 ${size === "small" ? 'text-xs' : 'text-sm'}`}>{label}</label>

            <ul className="flex flex-wrap text-xl w-full mt-1 -mx-px" style={{ height: "2.4rem" }}>
                {options.map((option, i) => 
                    <li key={i} className="w-1/3 px-px">
                        <div onClick={() => onClickHandler(option.value)} className={`flex items-center justify-center h-full w-full flex-wrap rounded ${(option.value === (targetValue || defaultValue)) ? 'bg-blue-100 text-blue-500' : 'cursor-pointer hover:bg-blue-100'}`}>
                            {option.icon}
                        </div>
                    </li>
                )}               
            </ul>
        </div>
    )
}
export default BtnGroup;
