import React, { Children } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiX } from 'react-icons/fi';

const Modal = ({ handleClose, children, isOpen, size, centered}) => {
    const modalVariant = {
        initial: { opacity: 0 },
        isOpen: { opacity: 1 },
        exit: { opacity: 0, transition: { delay: 0.25 } }
    };
    const containerVariant = {
        initial: { opacity: 0, scale: 0.6, transition: { type: 'tween', ease: 'easeIn', delay: 0.35 } },
        isOpen: { opacity: 1, scale: 1, transition: {  delay: 0.25 } },
        exit: { opacity: 0, scale: 0.6, transition: { type: 'tween', ease: 'easeOut' }}
    };

    var sizeClasses = '';
    if(!size || size === 'sm') { sizeClasses = "w-4/5 md:w-104 py-4 px-5"}
    else if(size === 'md') { sizeClasses = "w-full mx-6 md:mx-0 md:w-144 py-4 px-5"}
    else if(size === 'lg') { sizeClasses = "w-full mx-6 md:mx-0 md:w-3/4 xl:max-w-320 py-4 px-5"}
    else if(size === 'xl') { sizeClasses = "w-full mx-6 md:mx-12 w-full py-4 px-5"}
    else if(size === 'project') { sizeClasses = "w-full mx-6 md:mx-0 md:w-160 py-4 px-5"}

    return (
    <AnimatePresence>
        {isOpen && (
            <motion.div className="modalOverlay fixed z-50 left-0 top-0 w-full h-full bg-black bg-opacity-75"
                duration={0.5}
                initial={"initial"}
                animate={"isOpen"}
                exit={"exit"}
                variants={modalVariant}
            >
                <motion.div className={`modalContainer w-full flex justify-center ${centered ? 'h-full items-center' : 'mt-10 sm:mt-20'}`}
                    variants={containerVariant}>
                    <div className={`modal relative bg-white rounded shadow transition-all duration-300 ${sizeClasses}`} >
                        <div className="absolute w-auto inline-block p-2 rounded-full cursor-pointer bg-gray-900 transition duration-75 text-white text-opacity-75 hover:text-opacity-100" style={{ right: -15, top: -15 }} onClick={handleClose}><FiX /></div>
                        {children}
                    </div>
                </motion.div>
            </motion.div>
        )}
    </AnimatePresence>
    );
}

export default Modal