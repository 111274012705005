import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Tooltip } from 'react-tippy';
import {
    canvasElementsAtom,
    projectConfigurationAtom,
    selectedSceneElementsSelector,
    timelineCurrentTimeAtom,
    timelineStartTimeAtom,
    animationStateAtom
} from '../../utils/atomStore';
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
import Slider from 'react-rangeslider';
import { debounce } from 'lodash';
import { displayTime } from '@mattevans-dev/editsquare.core/utils/utils';


function TimelineRailMockup(props) {
    const projectConfiguration = useRecoilValue(projectConfigurationAtom);
    const canvasElements = useRecoilValue(selectedSceneElementsSelector);
    const [stateTime, setStateTime] = useRecoilState(timelineCurrentTimeAtom);
    const [timelineStartTime, setTimelineStartTime] = useRecoilState(
        timelineStartTimeAtom
    );
    const animationState = useRecoilValue( animationStateAtom );

    const debouncedHandleScrubbing = useCallback(
        debounce((nextValue, func) => func(nextValue), 100),
        []
    );
    return (
        <>
            <div className="flex items-center">

                <div className="flex items-center">
                    {animationState != "play" && (
                            <button
                                className="focus:outline-none"
                                onClick={() => {
                                    projectConfiguration.handleReset();
                                    projectConfiguration.handlePlay();
                                }}>
                                <BsPlayFill className="cursor-pointer h-12 w-12 shadow-sm bg-white text-indigo-600 rounded-full p-2.5" />
                            </button>
                    )}

                    {animationState && animationState != "pause" && (
                            <button
                               className="focus:outline-none"
                                onClick={() => {
                                    projectConfiguration.handlePause();
                                }}>
                                <BsPauseFill className="cursor-pointer h-12 w-12 shadow-sm bg-white text-indigo-600 rounded-full p-2.5" />
                            </button>
                    )}  

                </div>

                <div
                    className="flex-1 px-5"
                    style={{
                        width: projectConfiguration.duration
                            ? projectConfiguration.duration / 10
                            : 0,
                    }}
                >
                    <Slider
                        step={1000 / props.framerate}
                        value={stateTime}
                        max={projectConfiguration.duration}
                        onChange={e => {
                            setStateTime(e);
                            debouncedHandleScrubbing(e, projectConfiguration.handleScrubbing);
                        }}
                        tooltip={false}
                    />
                </div>

                <div className="flex">
                    <div className="hidden md:flex monospace tracking-tight leading-none bg-white bg-opacity-15 py-2 px-2.5 rounded">
                        <span className="text-white">{displayTime(stateTime, props.framerate)}</span>
                        <span className="text-indigo-100 text-opacity-25 px-1">/</span>
                        <span className="text-indigo-100 text-opacity-75">{displayTime(projectConfiguration.duration, props.framerate)}</span>
                    </div>
                    
                    <Tooltip
                        position="top"
                        size="small"
                        arrow={true}
                        title={'Previews are low-quality for real-time playback. <br />Final renders will be encoded in high quality.'}
                        theme="light"
                        duration={200}
                        style={{ display: 'flex', fontSize: 10 }}
                    >
                        <div className="flex items-center text-white bg-white bg-opacity-10 p-2 rounded text-xs leading-none ml-2"><span className="font-semibold inline-block mr-1">Quality:</span> 360p</div>
                    </Tooltip>
                </div>
            </div>
        </>
    );
}

export default TimelineRailMockup;
