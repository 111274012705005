import { isBrowser } from "../../utils/utils"

const fabric = isBrowser ? window.fabric : {};

function registerAnimatedCanvas() {
  fabric.AnimatedCanvas = fabric.util.createClass(fabric.Image, {
    type: "croppableimage",
    initialize: function(AnimatedCanvas, options) {
      options = options || {}
      this.callSuper("initialize", AnimatedCanvas, options)
      this._AnimatedCanvas = AnimatedCanvas
    },
    drawCacheOnCanvas: function(ctx) {
      // ctx.scale(1 / this.zoomX, 1 / this.zoomY);
      // ctx.drawImage(this._AECanvas, -this.cacheTranslationX, -this.cacheTranslationY);
      // ctx.scale(1 / this.zoomX, 1 / this.zoomY);
      ctx.drawImage(this._AnimatedCanvas, -this.width / 2, -this.height / 2)
    },
    _createCacheCanvas: function() {
      console.log("override!!!")
      this._cacheProperties = {}
      this._cacheCanvas = this._AnimatedCanvas
      console.log(this._cacheCanvas)
      this._cacheContext = this._cacheCanvas.getContext("2d")
      // debugger
      // this._updateCacheCanvas();
      // if canvas gets created, is empty, so dirty.
      this.dirty = true
    },
    render: function(ctx) {
      // do not render if width/height are zeros or object is not visible
      if (this.isNotVisible()) {
        return
      }
      if (
        this.canvas &&
        this.canvas.skipOffscreen &&
        !this.group &&
        !this.isOnScreen()
      ) {
        return
      }
      ctx.save()
      this._setupCompositeOperation(ctx)
      this.drawSelectionBackground(ctx)
      this.transform(ctx)
      this._setOpacity(ctx)
      this._setShadow(ctx, this)
      if (this.transformMatrix) {
        ctx.transform.apply(ctx, this.transformMatrix)
      }
      this.clipTo && fabric.util.clipContext(this, ctx)
      if (this.shouldCache()) {
        if (!this._cacheCanvas) {
          // console.log("create cache")
          this._createCacheCanvas()
        }
        // if (this.isCacheDirty()) {
        //   console.log('cache dirty')
        //   this.statefullCache && this.saveState({ propertySet: 'cacheProperties' });
        //   this.drawObject(this._cacheContext);
        //   this.dirty = false;
        // }
        // console.log('draw cache')
        this.drawCacheOnCanvas(ctx)
      } else {
        // console.log("remove cache and draw")
        this._removeCacheCanvas()
        this.dirty = false
        this.drawObject(ctx)
        if (this.objectCaching && this.statefullCache) {
          this.saveState({ propertySet: "cacheProperties" })
        }
      }
      this.clipTo && ctx.restore()
      ctx.restore()
    },
  })
}

export default registerAnimatedCanvas