import React from 'react';
import Slider from 'react-rangeslider';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function RangeInput({label, className, defaultValue, size, target, targetProperty, minValue = 0, maxValue=300, step=1 }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);

    const onChangeHandler = (e) => {
        const val = Math.round((e + Number.EPSILON) * 100) / 100;
        const updatedCanvasElement = { uid: target };
        if(Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => _.set(updatedCanvasElement, tp, val));
        } else {
            _.set(updatedCanvasElement, targetProperty, val);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    
    const targetValue = (Array.isArray(targetProperty)) ? 
        _.get(selectedCanvasElement, targetProperty[0]) :
        _.get(selectedCanvasElement, targetProperty);
    
    
    return (
        <div className={className}>
            <div className="flex flex-row -mx-2">
                <div className="flex-1 px-2">
                <label className={`block leading-4 font-medium text-gray-700 mb-0.5 ${size === "small" ? 'text-xs' : 'text-sm'}`}>{label}</label>
                    <div className="flex -mx-2 items-center">
                        <div className="flex-1 px-2">
                            <Slider
                                min={minValue}
                                max={maxValue}
                                step={step}
                                tooltip={false}
                                value={typeof targetValue !== "undefined" ? targetValue : null || defaultValue || 0}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-17 px-2"><input value={typeof targetValue !== "undefined" ? targetValue : null || defaultValue || 0} onChange={(event) => { onChangeHandler(Math.abs(event.target.value)); }} className="form-input w-full block sm:text-sm sm:leading-5" /></div>
            </div>
        </div>
    )
}
export default RangeInput;
