import React from "react"

export const TrimMarker = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={8} height={144} viewBox="0 0 8 144" className={props.className}>
    <path fill="#2d3748" d="M0 31h2v207H0z" />
    <path d="M0 0v32c4.4 0 8-4 8-9V9c0-5-3.6-9-8-9z" fill="#3f83f8" />
    <path
      d="M3.5 24c-.3 0-.5-.2-.5-.5v-15c0-.3.2-.5.5-.5h.1c.2 0 .4.2.4.5v15.1c0 .2-.2.4-.5.4z"
      fill="#fff"
    />
    </svg>
)
export const Debug = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
    </svg>
);
export const EyeOn = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
    </svg>
)
export const EyeOff = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokelinecap="round" strokelinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
      </svg>
)
export const Photo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
)

export const TextBox = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
    </svg>
)

export const Bin = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
    </svg>
)

export const ChevronUp = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
    </svg>
)

export const ChevronDown = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
)
export const Elements = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
    </svg>
)
export const Overlays = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
    </svg>
)
export const Transition = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
    </svg>
)
export const Music = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
    </svg>
)
export const Watermark = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16v2a2 2 0 01-2 2H5a2 2 0 01-2-2v-7a2 2 0 012-2h2m3-4H9a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-1m-1 4l-3 3m0 0l-3-3m3 3V3" />
    </svg>
)
export const Burger = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
    </svg>
)
export const Loading = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
)
export const Shapes = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" strokeLinecap="round" viewBox="0 0 24 24"className={props.className}>
      <path d="M18.4 14.9c1.6 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8c-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.2-2.8 2.8-2.8m0-2c-2.6 0-4.8 2.2-4.8 4.8 0 2.6 2.2 4.8 4.8 4.8 2.6 0 4.8-2.2 4.8-4.8 0-2.7-2.2-4.8-4.8-4.8zM9.1 14.9v5.6H3.5v-5.6h5.6m1.1-2H2.5c-.5 0-1 .4-1 1v7.7c0 .5.4 1 1 1h7.7c.5 0 1-.4 1-1v-7.7c-.1-.6-.5-1-1-1zM18 3.8L21 9h-6l3-5.2m0-2.3c-.6 0-1.2.3-1.5.9l-3.4 6c-.7 1.2.2 2.6 1.5 2.6h6.9c1.3 0 2.2-1.5 1.5-2.6l-3.4-6c-.4-.6-1-.9-1.6-.9z" />
      <path
        d="M9.6 5.6H7V3c0-.3-.2-.5-.5-.5h-.3c-.3 0-.6.2-.6.5v2.6H3c-.3 0-.5.2-.5.5v.3c0 .3.2.5.5.5h2.6v2.6c0 .3.2.5.5.5h.3c.3 0 .6-.2.6-.5V6.9h2.6c.3 0 .5-.2.5-.5v-.3c0-.3-.3-.5-.5-.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1}
        strokeMiterlimit={10}
      />
      </svg>
)
export const SizeSquare = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80" stroke="currentColor" className={props.className}>
      <defs>
        <radialGradient
          id="prefix__b"
          cx="11.324%"
          cy="100%"
          r="110.114%"
          fx="11.324%"
          fy="100%"
          gradientTransform="scale(.575 1) rotate(15.612 .155 1.305)"
        >
          <stop offset="0%" stopColor="#FED276" />
          <stop offset="13.366%" stopColor="#FDBD61" stopOpacity={0.975} />
          <stop offset="38.205%" stopColor="#F6804D" />
          <stop offset="100%" stopColor="#E83D5C" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="prefix__a"
          x1="21.316%"
          x2="100%"
          y1="33.469%"
          y2="66.531%"
        >
          <stop offset="0%" stopColor="#4E60D3" />
          <stop offset="44.251%" stopColor="#913BAF" />
          <stop offset="76.146%" stopColor="#D52D88" />
          <stop offset="100%" stopColor="#F26D4F" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillRule="nonzero" d="M0 46h80v34H0z" />
        <path fill="#DFE0E6" d="M43 66v7H7v-7h36zm30-13v7H7v-7h66z" />
        <path fill="url(#prefix__a)" fillRule="nonzero" d="M0 0h80v46H0z" />
        <path fill="url(#prefix__b)" fillRule="nonzero" d="M0 0h80v46H0z" />
        <path
          fill="#FFF"
          d="M46.006 11A6 6 0 0152 16.993v12.013A6 6 0 0146.006 35H33.993A6 6 0 0128 29.006V16.993A6 6 0 0133.993 11h12.013zm0 2.392H33.993a3.606 3.606 0 00-3.601 3.601v12.013a3.605 3.605 0 003.601 3.601h12.013a3.605 3.605 0 003.601-3.601V16.993a3.605 3.605 0 00-3.601-3.601zM40 16.879A6.128 6.128 0 0146.122 23c0 3.376-2.746 6.122-6.122 6.122S33.878 26.376 33.878 23s2.746-6.121 6.122-6.121zm0 2.392c-2.056 0-3.73 1.673-3.73 3.729s1.674 3.728 3.73 3.728 3.729-1.672 3.729-3.728-1.673-3.729-3.73-3.729zm6.446-4.056c.746 0 1.354.607 1.354 1.354a1.356 1.356 0 01-2.71 0c0-.747.608-1.354 1.356-1.354z"
        />
      </g>
    </svg>
)
export const SizeVertical = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 93" stroke="currentColor" className={props.className}>
      <path
        d="M5 0h38c1.3 0 2.6.5 3.5 1.5S48 3.7 48 5v83c0 2.8-2.2 5-5 5H5c-2.8 0-5-2.2-5-5V5c0-2.8 2.2-5 5-5zm19 91c2.2 0 4-1.8 4-4 0-1.1-.4-2.1-1.2-2.8-.7-.8-1.7-1.2-2.8-1.2-2.2 0-4 1.8-4 4 0 1.1.4 2.1 1.2 2.8.7.8 1.7 1.2 2.8 1.2zM18.5 3c-.3 0-.5.2-.5.5s.2.5.5.5h11c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-11z"
        fill="#fff"
        fillOpacity={0.3}
      />
      <path
        d="M4 7h40c.6 0 1 .4 1 1v72c0 .6-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#dfe0e6"
        d="M6 78h36v-5H6z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fffc00"
        d="M6 70h36V10H6z"
      />
      <path
        d="M24.1 50.6h-.2-.1c-1.3 0-2.2-.6-3-1.2-.6-.4-1.1-.8-1.7-.9-.3 0-.6-.1-.9-.1-.5 0-.9.1-1.2.1-.2 0-.3.1-.5.1-.1 0-.3 0-.4-.3-.1-.2-.1-.3-.1-.5-.1-.4-.2-.7-.3-.7-1.5-.1-2.5-.5-2.7-.9v-.1c0-.1.1-.3.2-.3 1.3-.2 2.5-.9 3.4-2 .8-.9 1.1-1.7 1.2-1.8.2-.4.2-.7.1-1-.2-.6-.9-.8-1.3-1-.1 0-.2 0-.3-.1-.4-.2-1.1-.5-1-1 .1-.3.5-.6.9-.6.1 0 .2 0 .3.1.4.2.8.3 1.1.3.4 0 .6-.2.6-.2v-.7c-.1-1.4-.3-3.3.2-4.4 1.4-3.1 4.5-3.4 5.3-3.4h.4c.9 0 3.9.2 5.3 3.4.5 1.1.4 2.9.3 4.4v.7s.2.2.6.2c.3 0 .7-.1 1-.3.1-.1.3-.1.3-.1.1 0 .3 0 .4.1.3.1.5.4.6.6 0 .2-.2.6-1 .9-.1 0-.2.1-.3.1-.5.1-1.2.4-1.4.9-.1.3-.1.6.1 1 .1.1 1.5 3.4 4.6 3.9.1 0 .2.1.2.3v.1c-.2.4-1.2.8-2.8 1.1-.2 0-.2.3-.3.7 0 .2-.1.3-.1.5s-.2.2-.3.2c-.1 0-.3 0-.5-.1-.3-.1-.7-.1-1.2-.1-.3 0-.6 0-.9.1-.6.1-1.1.5-1.7.9-.7.5-1.6 1.1-2.9 1.1"
        fill="#fff"
      />
      <path d="M24.1 51h-.2-.1c-1.4 0-2.4-.7-3.2-1.2-.5-.4-1-.7-1.5-.8-.8-.1-1.5 0-2 .1-.2 0-.4.1-.5.1-.5 0-.7-.3-.7-.5-.1-.2-.1-.4-.1-.5 0-.1-.1-.3-.1-.4-2.3-.4-2.8-.9-3-1.3 0-.1-.1-.2-.1-.3 0-.3.2-.6.5-.7 1.2-.2 2.3-.8 3.2-1.9.7-.8 1.1-1.6 1.1-1.7.2-.3.2-.5.1-.7-.1-.3-.8-.5-1.1-.7-.1 0-.2-.1-.3-.1-1.1-.6-1.3-1.1-1.2-1.5.1-.7 1.1-1.1 1.7-.8.5.2 1 .3 1.2.2v-.4c-.1-1.5-.2-3.5.3-4.6 1.5-3.4 4.7-3.7 5.7-3.7h.4c1 0 4.2.3 5.7 3.7.5 1.1.4 3.1.3 4.6v.4h.2c.2 0 .5-.1.9-.3.4-.2.8-.1 1 0 .5.2.8.5.8.9 0 .5-.4.9-1.2 1.3-.1 0-.2.1-.3.1-.4.1-1 .3-1.1.7-.1.2 0 .4.1.7.1.2 1.4 3.2 4.3 3.7.3.1.6.3.5.7 0 .1 0 .2-.1.3-.2.4-.6.9-3 1.3 0 .1-.1.3-.1.4 0 .2-.1.3-.1.5-.1.3-.4.5-.7.5-.2 0-.3 0-.6-.1-.5-.1-1.2-.2-2-.1-.5.1-1 .4-1.5.8-.8.6-1.8 1.3-3.2 1.3zm-.1-.8h.2c1.2 0 2-.6 2.7-1.1.6-.4 1.1-.8 1.8-.9.9-.1 1.7-.1 2.2.1.2 0 .3.1.4.1 0-.1.1-.3.1-.5.1-.5.2-.9.6-1 1.8-.3 2.3-.6 2.5-.8-3.2-.6-4.7-3.9-4.7-4.1-.2-.5-.3-.9-.1-1.3.3-.7 1.1-.9 1.6-1.1.1 0 .2-.1.3-.1.6-.2.8-.5.8-.5 0-.1-.1-.2-.3-.2-.2-.1-.4-.1-.5 0-.4.2-.8.3-1.2.3-.5 0-.8-.2-.8-.3l-.1-.1v-.8c.1-1.5.2-3.3-.2-4.3-1.3-3-4.2-3.2-5-3.2h-.4c-.8 0-3.7.2-5 3.2-.4 1-.3 2.8-.2 4.2v.8l-.3.2c-.1.1-.3.3-.9.3-.3 0-.8-.1-1.2-.3h-.1c-.3 0-.5.1-.6.2 0 .1.2.3.8.6.1 0 .2.1.3.1.5.2 1.3.4 1.6 1.1.2.4.1.8-.1 1.3 0 .1-.4 1-1.2 1.9-1 1.2-2.2 1.9-3.5 2.1.2.2.7.5 2.5.8.4.1.5.5.6 1 0 .2.1.3.1.5.1 0 .2 0 .4-.1.5-.1 1.4-.2 2.3-.1.7.1 1.3.5 1.8.9.8.5 1.5 1.1 2.7 1.1h.1z" />
    </svg>
)
export const Text = (props) => (
    <div className={props.className}>Aa</div>
)
export const Background = (props) => ( // CHANGE THIS
    <svg width={24} height={24} viewBox="0 0 24 24" className={props.className}>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            fill="#fff"
            opacity={0.7}
            style={{
                isolation: "isolate",
            }}
            fill="currentColor"
            d="M16.4 6L6 16.4v-2.8L13.6 6h2.8zM10.4 6L6 10.4V7.6L7.6 6h2.8zM18 7.6v2.8L10.4 18H7.6L18 7.6zM18 13.6v2.8L16.4 18h-2.8l4.4-4.4z"
        />
        <path
            d="M19 4a.945.945 0 011 1v14a.945.945 0 01-1 1H5a.945.945 0 01-1-1V5a.945.945 0 011-1h14m0-2H5a2.946 2.946 0 00-3 3v14a2.946 2.946 0 003 3h14a2.946 2.946 0 003-3V5a2.946 2.946 0 00-3-3z"
            fill="currentColor"
        />
    </svg>
)
export const Stagger = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={props.className}
    >
       <rect width="9" height="3" fill="fillCurrent" rx="1.5"></rect>
      <rect width="14" height="3" y="7" fill="fillCurrent" rx="1.5"></rect>
      <rect width="19" height="3" y="14" fill="fillCurrent" rx="1.5"></rect>
      <rect width="24" height="3" y="21" fill="fillCurrent" rx="1.5"></rect>
    </svg>
)
export const Play = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
)
export const Clock = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
)
export const Adjustments = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
    </svg>
)
export const Edit = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
    </svg>
)
export const Design = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
    </svg>
)
export const Slides = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
    </svg>
)
export const ArrowLeft = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
    </svg>
)
export const Question = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
)
export const Clone = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
    </svg>
)
export const Collection = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={props.className}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
    </svg>
)
export const Animation = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" preserveAspectRatio="xMidYMid meet" className={props.className}>
        <defs>
            <clipPath id="__lottie_element_239">
                <rect width="24" height="24" x="0" y="0">
                </rect>
            </clipPath>
        </defs>
        <g>
      <g className="prefix__motionIcon">
        <path
          fill="currentColor"
          d="M6.18 6c3.311 0 6 2.689 6 6s-2.689 6-6 6-6-2.689-6-6 2.689-6 6-6z"
          opacity={0.25}
        />
        <path
          fill="currentColor"
          d="M11.98 6c3.311 0 6 2.689 6 6s-2.689 6-6 6-6-2.689-6-6 2.689-6 6-6z"
          opacity={0.5}
        />
        <path
          fill="currentColor"
          d="M17.928 6c3.311 0 6 2.689 6 6s-2.689 6-6 6-6-2.689-6-6 2.689-6 6-6z"
        />
      </g>
    </g>
    </svg>

)

