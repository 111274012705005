import React from 'react';
import { get, set } from "lodash";
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function OriginPoint({ label, options, defaultValue, className, size, target, targetProperty }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);

    const onClickHandler = (e) => {
        const updatedCanvasElement = { uid: target };
        if (Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
        } else {
            set(updatedCanvasElement, targetProperty, e);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    return (
        <div className={`${className ? className : ''}`}>
            <label className={`block leading-4 font-medium text-gray-700 mb-1 ${size === "small" ? 'text-xs' : 'text-sm'}`}>{label}</label>

            <div className="mt-1">
                <div className="flex flex-row -mx-1 mb-1">
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"top_left" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('top_left')}></div></div>
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"top_center" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('top_center')}></div></div>
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"top_right" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('top_right')}></div></div>
                </div>
                <div className="flex flex-row -mx-1 mb-1">
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"center_left" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('center_left')}></div></div>
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"center_center" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('center_center')}></div></div>
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"center_right" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('center_right')}></div></div>
                </div>
                <div className="flex flex-row -mx-1">
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"bottom_left" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('bottom_left')}></div></div>
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"bottom_center" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('bottom_center')}></div></div>
                    <div className="px-1"><div className={`border h-2.5 w-2.5 rounded-sm ${"bottom_right" === (targetValue || defaultValue) ? 'bg-black border-transparent' : 'cursor-pointer hover:bg-gray-200 hover:border-gray-400'}`} onClick={() => onClickHandler('bottom_right')}></div></div>
                </div>
            </div>
        </div>
    )
}
export default OriginPoint;
