import React from 'react';
import Select from 'react-select';
import {get, set} from "lodash";
import { fontSelectStyle } from '../../utils/styles';
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

const options = [
    {label: "Open Sans", value: 'Open Sans'},
    {label: "Lato", value: 'Lato'},
    {label: "Raleway", value: 'Raleway'},
]; // move this into a seperate json file 

function FontSelect({ label, className, target, targetProperty }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);
    const onChangeHandler = (e) => {
        const updatedCanvasElement = { uid: target };
        if(Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
        } else {
            set(updatedCanvasElement, targetProperty, e);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    return (
        <div className={className || ''}>
            <label className="block text-sm leading-4 font-medium text-gray-700">{label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <Select
                    options={options}
                    menuPortalTarget={document.body}
                    styles={fontSelectStyle}
                    value={options.filter(option => option.value === targetValue)}
                    onChange={option => onChangeHandler(option.value)}
                />
            </div>
        </div>
    )
}
export default FontSelect;
