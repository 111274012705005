import React from 'react';
import { get, set } from "lodash";
import { Tooltip } from 'react-tippy';
import { tooltipOptions } from "@mattevans-dev/editsquare.core/utils/utils";
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function BtnControl({ label, options, defaultValue, tooltipTitle, content, className, size, target, targetProperty, onClick }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);

    const onClickHandler = (e) => {
        // if we have a onClick, use that instead
        if(onClick) {
            const updatedCanvasElement = onClick(selectedCanvasElement);
            setSelectedCanvasElement(updatedCanvasElement);
        } else {
            const updatedCanvasElement = { uid: target };
            if (Array.isArray(targetProperty)) {
                targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
            } else {
                set(updatedCanvasElement, targetProperty, e);
            }
            setSelectedCanvasElement(updatedCanvasElement);
        }
    }
    return (
        <div className={`${className ? className : ''}`}>
            {label && (
                <label className={`block leading-4 font-medium text-gray-700 mb-1 ${size === "small" ? 'text-xs' : 'text-sm'}`}>{label}</label>
            )}
            <Tooltip
                    title={tooltipTitle}
                    {...tooltipOptions}              
                    position="top"
                    theme="dark"
                    size="small"
                    className="w-full"
                    style={{ display: "inline-flex" }}
                >
                <div className="flex w-full items-center justify-center text-center border rounded text-lg py-1.5 cursor-pointer text-gray-600 border-gray-300 shadow-sm" onClick={() => onClickHandler(true)}>{content}</div>
            </Tooltip>
        </div>
    )
}
export default BtnControl;
