import React, { useEffect } from 'react';
import uploadcare from "uploadcare-widget";

const MockupUploads = (props) => {

    const buildUploadCare = () => {
        let aspectRatio = props.aspectRatio || "16:16";
        let tab = "file";
        let file = null;
        if (props.initialUpload?.url) {
            tab = "preview";
            file = uploadcare.fileFrom('uploaded', props.initialUpload.url);
        }
        var panel = uploadcare.openPanel("#uploadcare-elem", file, tab, {
            multiple: false,
            inputAcceptTypes: "image/*",
            imagesOnly: true,
            crop: aspectRatio,
            tabs: props.tabs,
            effects: 'crop,mirror,flip,blur,sharp,enhance,grayscale,invert'
        });
        panel.progress((tabName) => {
            if (tabName === 'preview') {
                panel.fileColl.__items[0].done(fileInfo => {
                    const onSelectFunc = props.onSelect || null; 
                    if(onSelectFunc) { 
                        onSelectFunc(fileInfo); 
                    } 
                });
            } else {
                const onDeselectFunc = props.onDeselect || null; 
                if(onDeselectFunc) { 
                    onDeselectFunc(); 
                } 
            }
        });
        panel.done((result) => {
            result.promise().done(fileInfo => {
                const onDoneFunc = props.onDone || null; 
                if(onDoneFunc) { 
                    onDoneFunc(fileInfo); 
                } 
            });
        });
    };

    useEffect(() => {
        buildUploadCare();
    }, []);

    return (
        <>
        <div id="uploadcare-elem"></div>
        </>
    );
}
export default MockupUploads;