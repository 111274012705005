export const selectStyle = { 
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    indicatorSeparator: (styles) => ({display:'none'}),
    control: (styles, { isFocused }) => ({
        ...styles,
        cursor: 'pointer',
        borderColor: isFocused ? '#63b3ed' : '#e2e8f0',
        ':hover': {
            ...styles[':hover'],
            borderColor: isFocused ? '#63b3ed' : '#cbd5e0'
        },
        outline: 'none',
        boxShadow: isFocused ? '0 0 0 3px rgba(66, 153, 225, 0.5)' : 'none',
      }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: 14 }),
    placeholder: (styles, state) => ({
        ...styles,
        fontSize: 14,
        color: '#718096'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: 13,
        padding: '7px 10px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isSelected ? '#e1effe' : '#ffffff',
        color: isSelected ? '#3f83f8' : '#2d3748',
        ':hover': {
            ...styles[':hover'],
            backgroundColor: '#e1effe',
            color: '#3f83f8'
        }
      }
    }
  };

export const fontSelectStyle = { 
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (styles, { isFocused }) => ({
        ...styles,
        cursor: 'pointer',
        borderColor: isFocused ? '#63b3ed' : '#e2e8f0',
        ':hover': {
            ...styles[':hover'],
            borderColor: isFocused ? '#63b3ed' : '#cbd5e0'
        },
        outline: 'none',
        boxShadow: isFocused ? '0 0 0 3px rgba(66, 153, 225, 0.5)' : 'none',
      }),
    singleValue: (styles, { data }) => ({ ...styles, fontFamily: data.value, fontSize: 14 }),
    placeholder: (styles, state) => ({
        ...styles,
        fontSize: 14,
        color: '#718096'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: data.value,
        fontSize: 13,
        padding: '7px 10px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isSelected ? '#e1effe' : '#ffffff',
        color: isSelected ? '#3f83f8' : '#2d3748',
        ':hover': {
            ...styles[':hover'],
            backgroundColor: '#e1effe',
            color: '#3f83f8'
        }
      }
    }
  };