import React from 'react';
import { Tooltip } from 'react-tippy';
import Slider from 'react-rangeslider';
import _, { set, get } from 'lodash';
import { tooltipOptions } from "@mattevans-dev/editsquare.core/utils/utils";
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function InputWithRange({ label, inputLabel, emptyLabel, className, value, target, targetProperty }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const onChangeHandler = (e) => {
        const updatedCanvasElement = { uid: target };
        if (Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
        } else {
            set(updatedCanvasElement, targetProperty, e);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);
    return (
        <div className={className || ''}>
            {label && (
                <label className="block text-sm leading-4 font-medium text-gray-700">{label}</label>
            )}            
            {emptyLabel && (
                <div className="h-4"></div> // useful for 2 cols which only requires 1 label
            )}
            <div className="mt-1 relative rounded-md shadow-sm">
                <input className="form-input block w-full pl-3 pr-8 sm:text-sm sm:leading-5" value={targetValue.toFixed(2) || 0} onChange={(event) => { onChangeHandler(Math.abs(event.target.value)); }} />
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 font-bold text-gray-400">{inputLabel}</div>
            </div>
        </div>
    )
}
export default InputWithRange;
