import React, { useState } from 'react';
import Switch from "react-switch";
import { FiCheck, FiX } from "react-icons/fi";
import _, { set, get } from 'lodash';
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function SwitchToggle({ label, defaultValue, className, target, targetProperty, onValue = true, offValue = false }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);

    const handleChange = (e) => {
        const val = e ? onValue : offValue;
        const updatedCanvasElement = { uid: target };
        set(updatedCanvasElement, targetProperty, val);
        setSelectedCanvasElement(updatedCanvasElement);
    }
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);
    
    let checkedValue = false;
    if(typeof defaultValue !== "undefined") {
        checkedValue = defaultValue !== offValue;
    } 
    if(typeof targetValue !== "undefined") {
        checkedValue = targetValue !== offValue;
    }

    return (
        <div className={className || ''}>
            <div className="flex flex-row justify-between items-center mb-3">

                <div><label className="block text-sm leading-4 font-medium text-gray-700">{label}</label></div>
                <div className="flex items-center h-full">
                    <Switch onChange={(e) => handleChange(e)} checked={checkedValue } height={18} width={36} offColor="#e2e8f0" onColor="#48bb78" activeBoxShadow="none" checkedIcon={<FiCheck className="text-white text-base h-full pl-1" />} uncheckedIcon={<div className="w-full h-full flex items-center justify-center pr-0.5"><FiX className="text-gray-600 text-opacity-75 text-xs" /></div>} />
                </div>
            </div>

        </div>
    )
}
export default SwitchToggle;
