import React from'react';
// import ColorInput from "../components/controls/colorInput"
// import ColorGradientInput from "../components/controls/colorGradientInput"
import RangeInput from '../components/controls/rangeInput';
import InputWithRange from '../components/controls/inputWithRange';
import InputInlineLabel from '../components/controls/inputInlineLabel';
import Input from '../components/controls/input';
import SelectDropdown from '../components/controls/selectDropdown';
import FontSelect from '../components/controls/fontSelect';
import SwitchToggle from '../components/controls/switch';
import BtnGroup from '../components/controls/btnGroup';
// import AngleControl from '../components/controls/angleControl';
// import ShadowAngleControl from '../components/controls/shadowAngleControl';
import ShadowRangeInput from '../components/controls/shadowRangeInput';
import BtnControl from '../components/controls/btnControl';
import OriginPoint from '../components/controls/originPoint';

// Holds the element configuration controls
export const controlsConfiguration = [
    {
        uid: "selectInput", 
        createComponent: (target, args) => {
            return (<SelectDropdown className={args.className || ''} label={args.label || "Color"} target={target} targetProperty={args.property || "fill"} defaultValue={args.defaultValue} options={args.options || []} />)
        }
    }, 
    {
        uid: "selectFont", 
        createComponent: (target, args) => {
            return (<FontSelect className={args.className || ''} label={args.label || "Color"} target={target} targetProperty={args.property || "fill"} options={args.options || []} />)
        }
    }, 
    // {
    //     uid: "colorInput", 
    //     createComponent: (target, args) => {
    //         return (<ColorInput className={args.className || ''} value={args.value} label={args.label || "Color"} target={target} targetProperty={args.property || "fill"} defaultValue={args.defaultValue} />)
    //     }
    // }, 
    // {
    //     uid: "colorGradientInput", 
    //     createComponent: (target, args) => {
    //         return (<ColorGradientInput className={args.className || ''} value={args.value} label={args.label || "Color"} target={target} targetProperty={args.property || "fill"} />)
    //     }, 
    // }, 
    {
        uid: "switchToggle", 
        createComponent: (target, args) => {
            return (<SwitchToggle className={args.className || ''} defaultValue={args.defaultValue} label={args.label || "Color"} target={target} targetProperty={args.property || "visible"} onValue={args.onValue} offValue={args.offValue} />)
        }
    }, 
    {
        uid: "rangeInput", 
        createComponent: (target, args) => {
            return (<RangeInput className={args.className || ''} label={args.label || "Stroke Width"} target={target} targetProperty={args.property || "strokeWidth"} minValue={args.minValue || 0} maxValue={args.maxValue || 100} step={args.step || 1} defaultValue={args.defaultValue} />)
        }
    },
    {
        uid: "inputWithRange", 
        createComponent: (target, args) => {
            return (<InputWithRange className={args.className || ''} label={args.label || "Font Size"} target={target} targetProperty={args.property || "fontSize"} minValue={args.minValue || 0} maxValue={args.maxValue || 100} step={args.step || 1} defaultValue={args.defaultValue}  />)
        }
    },
    {
        uid: "inputInlineLabel", 
        createComponent: (target, args) => {
            return (<InputInlineLabel className={args.className || ''} label={args.label || ""} inputLabel={args.inputLabel || ""} emptyLabel={args.emptyLabel || false} target={target} targetProperty={args.property || "fontSize"} />)
        }
    },
    {
        uid: "input", 
        createComponent: (target, args) => {
            return (<Input className={args.className || ''} label={args.label || ""} inputLabel={args.inputLabel || ""} emptyLabel={args.emptyLabel || false} target={target} targetProperty={args.property || "fontSize"} />)
        }
    },
    {
        uid: "btnControl", 
        createComponent: (target, args) => {
            return (<BtnControl className={args.className || ''} label={args.label} target={target} targetProperty={args.property} defaultValue={args.defaultValue} content={args.content} tooltipTitle={args.tooltipTitle} onClick={args.onClick} />)
        }
    }, 
    {
        uid: "btnGroup", 
        createComponent: (target, args) => {
            return (<BtnGroup className={args.className || ''} label={args.label} target={target} targetProperty={args.property} defaultValue={args.defaultValue} options={args.options || []} />)
        }
    }, 
    // {
    //     uid: "angleControl", 
    //     createComponent: (target, args) => {
    //         return (<AngleControl className={args.className || ''} label={args.label} target={target} targetProperty={args.property} defaultValue={args.defaultValue} />)
    //     }
    // }, 
    {
        uid: "originControl", 
        createComponent: (target, args) => {
            return (<OriginPoint className={args.className || ''} label={args.label} target={target} targetProperty={args.property} defaultValue={args.defaultValue} />)
        }
    }, 
    // {
    //     uid: "shadowAngleControl", 
    //     createComponent: (target, args) => {
    //         return (<ShadowAngleControl className={args.className || ''} label={args.label} target={target} targetProperty={args.property} defaultValue={args.defaultValue} />)
    //     }
    // }, 
    {
        uid: "shadowRangeInput", 
        createComponent: (target, args) => {
            return (<ShadowRangeInput className={args.className || ''} label={args.label || "Stroke Width"} target={target} targetProperty={args.property || "strokeWidth"} minValue={args.minValue || 0} maxValue={args.maxValue || 100} step={args.step || 1} defaultValue={args.defaultValue} />)
        }
    },
];


