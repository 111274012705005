import React from 'react';
import { Tooltip } from 'react-tippy';
import Slider from 'react-rangeslider';
import _, { set, get } from 'lodash';
import { tooltipOptions } from '@mattevans-dev/editsquare.core/utils/utils';
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function InputWithRange({ label, defaultValue, className, target, targetProperty, minValue = 0, maxValue=300, step=1 }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const onChangeHandler = (e) => {
        const updatedCanvasElement = { uid: target };
        if(Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
        } else {
            set(updatedCanvasElement, targetProperty, e);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);
    return (
        <div className={className || ''}>
        <label className="block text-sm leading-4 font-medium text-gray-700">{label}</label>
        <Tooltip
                html={
                <div className="w-40">
                <Slider
                    min={minValue}
                    max={maxValue}
                    step={step}
                    value={targetValue || defaultValue || 0}
                    onChange={onChangeHandler}
                />
                </div>}
                trigger="click"
                position="bottom"
                {...tooltipOptions}
                distance={10}
                theme="light"
                size="small"
                style={{ display: "inline-flex" }}
                interactive={true}
            >
                <div className="mt-1 relative rounded-md shadow-sm">
                    <input className="form-input block w-full pr-9 sm:text-sm sm:leading-5" value={targetValue || defaultValue || 0} onChange={(event) => { onChangeHandler(Math.abs(event.target.value)); }} />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 font-bold text-gray-400">px</div>
                </div>
            </Tooltip>
        </div>
    )
}
export default InputWithRange;
