import React from 'react';
import Select from 'react-select';
import {get, set} from "lodash";
import { selectStyle } from '../../utils/styles';
import { useRecoilState } from 'recoil';
import { selectedCanvasElementSelector } from '../../utils/atomStore';

function SelectDropdown({ label, options, defaultValue, className, size, target, targetProperty }) {
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const targetValue = (Array.isArray(targetProperty)) ? get(selectedCanvasElement, targetProperty[0]) : get(selectedCanvasElement, targetProperty);
    const onChangeHandler = (e) => {
        const updatedCanvasElement = { uid: target };
        if(Array.isArray(targetProperty)) {
            targetProperty.forEach(tp => updatedCanvasElement[tp] = e);
        } else {
            set(updatedCanvasElement, targetProperty, e);
        }
        setSelectedCanvasElement(updatedCanvasElement);
    }
    return (
        <div className={`${className ? className : ''}`}>
            <label className={`block leading-4 font-medium text-gray-700 mb-1 ${size === "small" ? 'text-xs' : 'text-sm'}`}>{label}</label>
            <div className={`relative rounded-md shadow-sm`}>
                <Select
                    options={options}
                    styles={selectStyle}
                    menuPortalTarget={document.body}
                    value={options.filter(option => option.value === (targetValue || defaultValue))}
                    onChange={option => onChangeHandler(option.value)}
                />
            </div>
        </div>
    )
}
export default SelectDropdown;
