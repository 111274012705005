export const fontsConfig = [
    {
        "label": "Cabin",
        "value": "Cabin",
        "weights": [
            {
                "label": "Regular",
                "value": 400
            },
            {
                "label": "Medium",
                "value": 500
            },
            {
                "label": "Semi Bold",
                "value": 600
            },
            {
                "label": "Bold",
                "value": 700
            }
        ]
    },
    {
        "label": "Open Sans",
        "value": "Open Sans",
        "weights": [
            {
                "label": "Extra Light",
                "value": 300
            },
            {
                "label": "Light",
                "value": 400
            },
            {
                "label": "Regular",
                "value": 500
            },
            {
                "label": "Bold",
                "value": 600
            }
        ]
    },
    {
        "label": "Lato",
        "value": "Lato"
    },
    {
        "label": "Raleway",
        "value": "Raleway"
    }
];