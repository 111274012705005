import React, { useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useBatching } from 'recoil-undo';
import { Tooltip } from 'react-tippy';
import { SketchPicker } from 'react-color';
import { selectedCanvasElementSelector } from '../../utils/atomStore';
import elementsConfiguration from '@mattevans-dev/editsquare.core/utils/elementsConfiguration';
import { controlsConfiguration } from '../../utils/controlsConfiguration';
import Accordion from '../accordion';

function RightRail(props) {
    const [openedPanel, setOpenedPanel] = useState('drop shadow');
    const [selectedCanvasElement, setSelectedCanvasElement] = useRecoilState(selectedCanvasElementSelector);
    const elementType = selectedCanvasElement ? selectedCanvasElement.elementType : null;

    // TODO: Put this into a util function. Get friendlyElementTypeName
    var elementName = null;
    if (elementType === "FbcImage") {
        elementName = "Image";
    } else if (elementType === "video") {
        elementName = "Video";
    } else if (elementType === "FbcGif") {
        elementName = "GIF";
    }

    const renderElementControls = (controls) => {
        return controls.map((c, i) => {
            if (c.controlType === "group") {
                const children = c.appendProperty ?
                    c.children.map(o => {
                        const newProp = Array.isArray(o.args.property) ?
                            o.args.property.map(p => c.appendProperty + p) :
                            c.appendProperty + o.args.property;
                        const mergedArgs = { args: { property: newProp } };
                        const updatedArgs = _.merge({}, o, mergedArgs)
                        // console.log(o, mergedArgs, updatedArgs);
                        return updatedArgs;
                    }) :
                    c.children;
                return (
                    <Accordion icon={c.icon || "textbox"} name={c.label || "text box"} expanded={openedPanel} setExpanded={setOpenedPanel}>
                        {renderElementControls(children || [])}
                    </Accordion>
                )
            } else if (c.controlType === "inlinegroup") {
                const children = c.appendProperty ?
                    c.children.map(o => {
                        const newProp = Array.isArray(o.args.property) ?
                            o.args.property.map(p => c.appendProperty + p) :
                            c.appendProperty + o.args.property;
                        const mergedArgs = { args: { property: newProp } };
                        const updatedArgs = _.merge({}, o, mergedArgs)
                        // console.log(o, mergedArgs, updatedArgs);
                        return updatedArgs;
                    }) :
                    c.children;
                return (
                    <>
                        <div className="flex px-3">
                            {renderElementControls(children || [])}
                        </div>
                        <div className="flex px-3 mb-3 mx-0.5">
                            <div className="w-1/2 px-1.5">
                                
                                {selectedCanvasElement && typeof selectedCanvasElement.image !== "undefined" && typeof selectedCanvasElement.image.url !== "undefined" && (
                                    <img src={selectedCanvasElement.image.url} className="rounded object-contain h-17 w-full bg-gray-200" />
                                )}
                            </div>
                            <div className="w-1/2 px-1.5 text-xs">
                                {elementName && (
                                    <div>{elementName}</div>
                                )}
                                <div className="cursor-pointer text-red-500">Replace Media</div>
                            </div>
                        </div>
                    </>
                )
            } else {
                const elementControl = _.find(controlsConfiguration, { uid: c.controlType });
                return (elementControl.createComponent(selectedCanvasElement.uid, c.args || {}));
            }
        });

    }

    const getElementControls = () => {
        if (selectedCanvasElement) {
            const elementConfiguration = _.find(elementsConfiguration, { uid: selectedCanvasElement.elementType });
            const controls = elementConfiguration.controls || [];
            return renderElementControls(controls);
        }
    }

    return (
        <div className="mt-2 overflow-hidden" id="log">
            {getElementControls()}
        </div>
    )
}

export default RightRail;
